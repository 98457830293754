import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p"><a parentName="strong" {...{
          "href": "https://crossfittheville.files.wordpress.com/2011/05/images.jpg"
        }}><img parentName="a" {...{
            "src": "https://crossfittheville.files.wordpress.com/2011/05/images.jpg?w=615",
            "alt": null,
            "title": "images"
          }}></img>{`{.alignnone
.size-full
.wp-image-275}`}</a></strong></p>
    <p><strong parentName="p">{`Thursday, May 19th`}</strong></p>
    <p>{`Complete as many rounds as possible in five minutes of:\\
275 pound Deadlift, 3 reps\\
115 pound Push press, 7 reps`}</p>
    <p>{`Then:`}</p>
    <p>{`21-15-9:`}</p>
    <p>{`Front Squats (M-115/W-75)`}</p>
    <p>{`Pullups`}</p>
    <p>{`Box Jumps (M-24″/W-18″)`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      